"use strict";

// Custom Event Polyfill to fix issue in IE11
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  window.CustomEvent = CustomEvent;
})();

import { ready, bindGlobalResizeMessage } from "@wearegood/good-utilities";

// import ShowhideAPI from "Modules/Showhide";
import ModalAPI from "Modules/Modal";
// import SmartImageAPI from "Modules/SmartImage";
// import InlineVideoAPI from "Modules/InlineVideo";
// import NavigationAPI from "Modules/Navigation";
// import TabsAPI from "Modules/Tabs";
// import CarouselAPI from "Modules/Carousel";
// import LoaderAPI from "Modules/Loader";
// import GridAPI from "Modules/Grid";
 
/**
 * initialiseComponentModules - call module init functions
 *
 * @returns {type} Description
 */ 
function initialiseComponentModules() {
  // NavigationAPI.initialiseNavigation();
  // SmartImageAPI.initSmartImages(); 
  // InlineVideoAPI.initInlineVideos();
  ModalAPI.initialiseModals();
  // TabsAPI.initialiseTabs();
  // ShowhideAPI.initialiseShowhide();
  // CarouselAPI.initialiseCarousels();
  // LoaderAPI.initLoader();
  // GridAPI.initGrids();

  bindGlobalResizeMessage();
}

ready(initialiseComponentModules);
