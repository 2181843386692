// Modal Components Module
"use strict";

////////////////////
// Module Imports //
//////////////////// 

import PubSub from "pubsub-js";
import { createNodeFromHTML, createCustomEvent, messages as MESSAGES, trackPageEvent } from "@wearegood/good-utilities";

import * as CONSTANTS from "Modules/Modal/constants";

////////////////////// 
// Module Constants //
//////////////////////


////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

/** 
 *
 *
 * @class Modal
 */
export default class Modal {

  /**
   * Creates an instance of Modal.
   * @param {*} element
   * @param {*} modalType
   * @param {*} modalID
   * @memberof Modal
   */
  constructor(content, modalType, modalID) {
    this.modal = createNodeFromHTML(CONSTANTS.MODAL_TEMPLATE).item(0);
    this.modalScreen = createNodeFromHTML(CONSTANTS.MODAL_SCREEN_TEMPLATE).item(0);
    
    this.modalID = modalID;
    this.modalType = modalType;
    this.modalContent = content;
    
    this.closeButton = this.modal.querySelector(CONSTANTS.SEL_MODAL_CLOSE);

    this.bindCustomMessageEvents();
    this.subscribeToEvents();

    CONSTANTS.BODY_ELEMENT.appendChild(this.modal);
    CONSTANTS.BODY_ELEMENT.appendChild(this.modalScreen);

    if (this.modalType === "inpage") {
      this.displayPageContentInModal();
    } else if (this.modalType === "image") {
      this.displaySmartImageInModal();
    } else if (this.modalType === "iframe") {
      this.displayIframeInModal();
    }

    PubSub.publish(MESSAGES.modalOpened);
  }

  /**
   *
   *
   * @memberof Modal
   */
  displaySmartImageInModal () {
    this.modal.classList.add(CONSTANTS.MODAL_CLASSES.image);

    const modalContent = this.modal.querySelector(CONSTANTS.SEL_MODAL_CONTENT);
    modalContent.appendChild(this.modalContent);

    //BODY_ELEMENT.classList.add("modalDisplayed");
    this.positionModal();
    PubSub.publish(MESSAGES.contentChange, this.modalContent);
  }

    /**
   *
   *
   * @memberof Modal
   */
  displayIframeInModal () {
    this.modal.classList.add(CONSTANTS.MODAL_CLASSES.iframe);

    const modalContent = this.modal.querySelector(CONSTANTS.SEL_MODAL_CONTENT);
    modalContent.appendChild(this.modalContent);

    this.positionModal();
    PubSub.publish(MESSAGES.contentChange, this.modalContent);
    let displayDelay = setTimeout(this.displayModal.bind(this), 50);
  } 

  /**
   *
   *
   * @memberof Modal
   */
  positionModal () {
    CONSTANTS.BODY_ELEMENT.classList.add(CONSTANTS.MODAL_CLASSES.positioned);
  }

  /**
   *
   *
   * @memberof Modal
   */
  displayModal () {
    CONSTANTS.BODY_ELEMENT.classList.add(CONSTANTS.MODAL_CLASSES.displayed);
  }

  /**
   *
   *
   * @memberof Modal
   */
  activateModal () {
    CONSTANTS.BODY_ELEMENT.classList.add(CONSTANTS.MODAL_CLASSES.loaded);
    this.positionModal();

    trackPageEvent("Modal Image", "Modal Opened", "Image ID: " + thisModalID);

    // let delayPosition = setTimeout(this.positionModal.bind(this), 1000);
  }

  /**
   *
   *
   * @memberof Modal
   */
  closeModal () {
    CONSTANTS.BODY_ELEMENT.removeChild(this.modal); 
    CONSTANTS.BODY_ELEMENT.removeChild(this.modalScreen);
    CONSTANTS.BODY_ELEMENT.classList.remove(CONSTANTS.MODAL_CLASSES.displayed);
    CONSTANTS.BODY_ELEMENT.classList.remove(CONSTANTS.MODAL_CLASSES.positioned);
    CONSTANTS.BODY_ELEMENT.classList.remove(CONSTANTS.MODAL_CLASSES.loaded);   
  }

  /**
   * 
   *
   * @memberof Modal
   */
  bindCustomMessageEvents () {
    this.modal.addEventListener(
      "closeModal",
      this.closeModal.bind(this)
    );

    this.modal.addEventListener(
      "updatelayout",
      this.positionModal.bind(this)
    );

    this.modal.addEventListener(
      "activateModal",
      this.activateModal.bind(this)
    );

    this.modalScreen.addEventListener(
      "closeModal",
      this.closeModal.bind(this)
    );
  }

  /**
   * Subscribe object to Global Messages
   * @function
   */
  subscribeToEvents () {
    PubSub.subscribe(MESSAGES.imageLoaded, () => {
      this.modal.dispatchEvent(createCustomEvent("activateModal"));
    });
  }
}

