export const MODAL_TEMPLATE = `
  <div class="cp_Modal" aria-modal="true">
    <div id="confirmation-popup" class="cp_Modal__inner">
      <div class="cp_Modal__content">
        <div class="cp_Modal__close">
          <a class="cp_Modal__closeLink" href="#" title="Close this modal">Close</a>
        </div>
      </div>
    </div>
  </div>`;

export const MODAL_SCREEN_TEMPLATE = `<div class='cp_ModalScreen'></div>`;

export const SEL_MODAL_LINK = "[data-modal-source]";
export const SEL_MODAL_CLOSE = ".cp_Modal__closeLink";
export const SEL_MODAL_CONTENT = ".cp_Modal__content";
export const SEL_MODAL_SCREEN = ".cp_ModalScreen";

export const MODAL_CLASSES = {
  image: "cp_Modal--image", 
  iframe: "cp_Modal--iframe",
  displayed: "modal_Displayed",
  loaded: "modal_Loaded",
  positioned: "modal_Positioned",
  contentHolder: "cp_Modal__contentHolder"
}

export const BODY_ELEMENT = document.getElementsByTagName('body')[0];