"use strict";

////////////////////
// Module Imports //
//////////////////// 

import * as CONSTANTS from "Modules/Modal/constants";
import Modal from "Modules/Modal/Modal";
import { createDelegatedEventListener, createGlobalMessenger as createGlobal, messages as MESSAGES } from "@wearegood/good-utilities";


////////////////////// 
// Module Constants //
//////////////////////

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

/**
 * ModalLinkManager - Class for managing links that generate page modals
 *
 * @class ModalLinkManager
 */
class ModalLinkManager {
  /**
   *Creates an instance of ModalLinkManager.
   * @memberof ModalLinkManager
   */
  constructor() {
    this.modalLinkContent = document.createElement("div");
    this.modalLinkContent.classList.add(CONSTANTS.MODAL_CLASSES.contentHolder);

    // Call initial methods
    this.subscribeToEvents();
  }

  /**
   *
   *
   * @param {object} data
   * @memberof ModalLinkManager
   */
  createModalContent(linkElement) {
    const MODAL_LINK = linkElement;
    const MODAL_LINK_ID = MODAL_LINK.getAttribute("id") || "unidentified";
    const MODAL_LINK_URL = MODAL_LINK.getAttribute("href");
    const MODAL_MODE = MODAL_LINK.dataset.modalSource;

    let modalContent;    

    this.modalLinkContent.innerHTML = "";

    if (MODAL_MODE === "iframe") {
      modalContent = `<iframe class="cp_Modal__iframe" src="${MODAL_LINK_URL}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
    } else if (MODAL_MODE === "image") {
      modalContent = `<div class="ob_Media--image ob_Media" data-image-load="pageload" data-image-config='{ "type" : "inline", "reload" : true }' data-src-set='{ "max": "${MODAL_LINK_URL}"}'></div>`;
    }

    if (modalContent) {
      this.modalLinkContent.innerHTML = modalContent;
      this.createModal(MODAL_MODE, MODAL_LINK_ID);
    } 
  }

  /**
   *
   *
   * @memberof ModalLinkManager
   */
  createModal(mode, id) {
    const NEW_MODAL = new Modal(this.modalLinkContent, mode, id);
  }

  /**
   *
   *
   * @memberof ModalLinkManager
   */
  subscribeToEvents() {
    PubSub.subscribe(MESSAGES.displayModal, (topic, data) => {
      let modalLink;

      if (data.target.matches(CONSTANTS.SEL_MODAL_LINK)) {
        modalLink = data.target;
      } else {
        modalLink = data.target.closest(CONSTANTS.SEL_MODAL_LINK);
      } 

      if(modalLink) {
        this.createModalContent(modalLink);
      }
    });
  }
}

/**
 * delegateEvents - Create delegated event listeners for the components managed within this module
 *
 * @returns {type} Description
 */
function delegateEvents() {
  createDelegatedEventListener("click", CONSTANTS.SEL_MODAL_CLOSE, "closeModal");
  createDelegatedEventListener("click", CONSTANTS.SEL_MODAL_SCREEN, "closeModal");
  createGlobal("click", CONSTANTS.SEL_MODAL_LINK, MESSAGES.displayModal, true);
}

/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export default function initialiseModals() {
  // Create delegated event listeners for the components within this module
  delegateEvents();

  const newModalLinkManager = new ModalLinkManager();
}